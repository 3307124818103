import React from 'react';
import * as R from 'ramda';
import { connect } from 'react-redux';
import { useLocation } from 'react-router-dom';
import PropTypes from 'prop-types';
import { Box } from '@material-ui/core';

import { withStyles } from '@material-ui/core/styles';
import { withTranslation } from 'react-i18next';

import RichText from '../../utils/RichText';

const inlineStyles = theme => ({
  container: {
    backgroundColor: '#f1cfcf',
    borderTop: `dashed 1px ${theme.palette.status.error}`,
    bottom: 0,
    padding: theme.spacing(2),
    position: 'fixed',
    width: '100%',
    zIndex: 1,
  },
  text: {
    fontWeight: 'bold',
    '& p': {
      marginBottom: 0,
    },
  },
});

const InfoBanner = ({ classes, brandSettings, localeLong }) => {
  const infoBanner = R.path(['infobanner', 'fields'], brandSettings);
  const showOn = R.path(['showOn'], infoBanner);
  const location = useLocation();

  if (!infoBanner) return null;

  if (showOn && showOn.length > 0) {
    const locationArr = location.pathname.split('/');
    const pageSlug = locationArr[locationArr.length - 1];

    if (locationArr.length === 2 && !showOn.includes('Service selection (step 1)')) {
      return null;
    } else if (
      locationArr.length === 3 &&
      pageSlug !== 'details' &&
      pageSlug !== 'confirmed' &&
      !showOn.includes('Time selection (step 2)')
    ) {
      return null;
    } else if (pageSlug === 'details' && !showOn.includes('Contact information form (step 3)')) {
      return null;
    } else if (pageSlug === 'confirmed' && !showOn.includes('Booking confirmation (step 4)')) {
      return null;
    }
  }

  const content = R.path([`contentIn${localeLong}`], infoBanner);

  return (
    <Box className={classes.container}>
      <RichText textData={content} className={classes.text} />
    </Box>
  );
};

InfoBanner.propTypes = {
  classes: PropTypes.object,
};

InfoBanner.defaultProps = {
  classes: {},
};

const mapStateToProps = state => ({
  localeLong: R.path(['contentful', 'localeLong'], state),
  brandSettings: R.path(['contentful', 'layout'], state),
});

export default withStyles(inlineStyles)(withTranslation('translations')(connect(mapStateToProps)(InfoBanner)));
