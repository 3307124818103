import React from 'react';
import * as R from 'ramda';
import { NavLink, Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { saveLocale } from '../../store/actions/contentfulActions';

import { withStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import Grid from '@material-ui/core/Grid';
import Hidden from '@material-ui/core/Hidden';
import Button from '@material-ui/core/Button';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import Icon from '@material-ui/core/Icon';
import Logo from '../subcomponents/Logo';
import { withTranslation } from 'react-i18next';

const inlineStyles = theme => ({
  appBar: {
    backgroundColor: 'rgba(255, 255, 255, 1)',
    boxShadow: 'none',
    borderBottom: '1px solid rgba(205, 205, 205, 1)',
  },
  menuButton: {
    borderRadius: 1,
    height: 40,
    minWidth: 0,
    padding: 0,
    width: 44,
  },
  menuItem: {
    padding: 0,
    '& > a': {
      paddingLeft: 15,
      paddingRight: 15
    }
  },
  navButton: {
    color: '#000',
    '&:hover': {
      textDecoration: 'underline',
    },
  },
  loginButton: {
    marginBottom: '10px',
    marginLeft: '20px',
    [theme.breakpoints.up('md')]: {
      marginBottom: 0,
    },
  },
  gridContainer: {
    [theme.breakpoints.up('lg')]: {
      paddingLeft: 20,
      paddingRight: 20,
    },
  },
});

/**
 * Navigation
 * Use to display the navigation header
 * Contains logo and language menu or hamburger menu of the same contents on mobile
 */

const Navigation = ({ classes, i18n, saveLocale, history, logoImage, t }) => {
    
  const getLocale = () => i18n.language;

  const changeLanguage = lng => {
    history.push(`/${lng}`);
  };



  const [anchorEl, setAnchorEl] = React.useState(null);


  const handleClick = event => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const underlineLink = lang => (getLocale() === lang ? { textDecoration: 'underline' } : {});

  return (
    <AppBar position="static" className={classes.appBar}>
      <Toolbar>
        <Grid container spacing={0} alignItems="center" className={classes.gridContainer}>
          <Grid item xs={6} style={{ textAlign: 'left' }}>
            <NavLink to={`/${getLocale()}`} aria-label={t('views.navigation.toHome')}>
              <Logo logoImage={logoImage} />
            </NavLink>
          </Grid>
          <Grid item xs={6} style={{ textAlign: 'right' }}>
            <Hidden xsDown>
              <IconButton
                className={classes.navButton}
                disableRipple
                onClick={() => changeLanguage('fi')}
                style={underlineLink('fi')}>
                <Typography variant="body1">{t('views.navigation.inFinnish')}</Typography>
              </IconButton>
              <IconButton
                className={classes.navButton}
                disableRipple
                onClick={() => changeLanguage('sv')}
                style={underlineLink('sv')}>
                <Typography variant="body1">{t('views.navigation.inSwedish')}</Typography>
              </IconButton>
              <IconButton
                className={classes.navButton}
                disableRipple
                onClick={() => changeLanguage('en')}
                style={underlineLink('en')}>
                <Typography variant="body1">{t('views.navigation.inEnglish')}</Typography>
              </IconButton>
              <Button
                disableRipple
                className={classes.loginButton}
                variant="outlined"
                component={Link}
                to={`/${getLocale()}/login`}>
                {t('views.appointmentLogin.ownReservation')}
              </Button>
            </Hidden>

            <Hidden smUp>
              <Button
                disableRipple
                className={classes.menuButton}
                variant="outlined"
                aria-label={t('views.navigation.menu')}
                aria-controls="simple-menu"
                aria-haspopup="true"
                onClick={handleClick}>
                <Icon fontSize="large">menu</Icon>
              </Button>
              <Menu
                id="simple-menu"
                anchorEl={anchorEl}
                keepMounted
                open={Boolean(anchorEl)}
                onClose={handleClose}
                getContentAnchorEl={null}
                anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
                transformOrigin={{ vertical: 'top', horizontal: 'center' }}>
                <MenuItem className={classes.menuItem}>
                  <IconButton disableRipple component={Link} to={`/${getLocale()}/login`}>
                    <Typography variant="body1">{t('views.appointmentLogin.ownReservation')}</Typography>
                  </IconButton>
                </MenuItem>
                <MenuItem className={classes.menuItem}>
                  <IconButton
                    disableRipple
                    component={Link}
                    onClick={() => changeLanguage('fi')}
                    style={underlineLink('fi')}>
                    <Typography variant="body1">{t('views.navigation.inFinnish')}</Typography>
                  </IconButton>
                </MenuItem>
                <MenuItem className={classes.menuItem}>
                  <IconButton
                    disableRipple
                    component={Link}
                    onClick={() => changeLanguage('sv')}
                    style={underlineLink('sv')}>
                    <Typography variant="body1">{t('views.navigation.inSwedish')}</Typography>
                  </IconButton>
                </MenuItem>
                <MenuItem className={classes.menuItem}>
                  <IconButton
                    disableRipple
                    component={Link}
                    onClick={() => changeLanguage('en')}
                    style={underlineLink('en')}>
                    <Typography variant="body1">{t('views.navigation.inEnglish')}</Typography>
                  </IconButton>
                </MenuItem>
              </Menu>
            </Hidden>
          </Grid>
        </Grid>
      </Toolbar>
    </AppBar>
  );
};

const mapStateToProps = state => ({
  logoImage: R.path(['contentful', 'layout', 'logo', 'fields', 'file', 'url'], state),
});

const mapDispatchToProps = dispatch => ({
  saveLocale: locale => dispatch(saveLocale(locale)),
});

export default withStyles(inlineStyles)(
  withTranslation('translations')(connect(mapStateToProps, mapDispatchToProps)(Navigation)),
);
