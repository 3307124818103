import * as R from 'ramda';

import {
  RECEIVE_SERVICES,
  RECEIVE_SUBSERVICES,
  RECEIVE_THANKYOUPAGE,
  RECEIVE_LANDINGPAGE,
  RECEIVE_LAYOUT,
  RECEIVE_REDIRECTIONS,
  SAVE_LOCALE,
  SAVE_USED_REDIRECTION,
  CLEAR_USED_REDIRECTION,
  CLEAR_DATA_CMS
} from '../actions/contentfulActions';

const initialState = {
  services: [],
  servicesInitialLoadComplete: false,
  subServices: [],
  subServicesInitialLoadComplete: false,
  thankYouPage: [],
  thankYouPageInitialLoadComplete: false,
  landingPage: [],
  landingPageInitialLoadComplete: false,
  redirectionInitialLoadComplete: false,
  layoutInitialLoadComplete: false,
  errorConnectingContentful: false,
  locale: 'fi',
  localeLong: 'finnish',
  redirections: [],
  redirection: {},
};

export default function contentfulReducer(state = initialState, action) {
  switch (action.type) {
    case RECEIVE_SERVICES:

      const services = R.path(['services'], action);

      return {
        ...state,
        servicesInitialLoadComplete: true,
        errorConnectingContentful: false,
        services
      };
    case RECEIVE_SUBSERVICES:
      return {
        ...state,
        subServicesInitialLoadComplete: true,
        errorConnectingContentful: false,
        subServices: R.path(['subServices'], action)
      };
    case RECEIVE_THANKYOUPAGE:
      return {
        ...state,
        thankYouPageInitialLoadComplete: true,
        errorConnectingContentful: false,
        thankYouPage: R.path(['thankYouPage'], action)
      };
    case RECEIVE_LANDINGPAGE:
      return {
        ...state,
        landingPageInitialLoadComplete: true,
        errorConnectingContentful: false,
        landingPage: R.path(['landingPage'], action)
      };
    case RECEIVE_REDIRECTIONS:
        return {
          ...state,
          redirectionInitialLoadComplete: true,
          errorConnectingContentful: false,
          redirections: R.path(['redirections'], action)
    };
    case SAVE_USED_REDIRECTION:
        return {
          ...state,
          redirectionInitialLoadComplete: true,
          redirection: R.path(['redirection'], action)
    };
    case CLEAR_USED_REDIRECTION:
        return {
          ...state,
          redirection: {}
    };
    case `${RECEIVE_SERVICES}_FAILURE`:
      return {
        ...state,
        errorConnectingContentful: true
      };
    case RECEIVE_LAYOUT:
        return {
          ...state,
          layoutInitialLoadComplete: true,
          errorConnectingContentful: false,
          layout: R.path(['layout'], action)
        };
    case `${RECEIVE_SUBSERVICES}_FAILURE`:
      return {
        ...state,
        errorConnectingContentful: true
      }
    case `${RECEIVE_THANKYOUPAGE}_FAILURE`:
      return {
        ...state,
        errorConnectingContentful: true
      }
    case `${RECEIVE_LANDINGPAGE}_FAILURE`:
        return {
          ...state,
          errorConnectingContentful: true
        }
    case `${RECEIVE_REDIRECTIONS}_FAILURE`:
        return {
          ...state,
          errorConnectingContentful: true
        }
    case SAVE_LOCALE:
      return {
        ...state,
        locale: R.path(['locale'], action),
        localeLong: R.path(['locale'], action) === 'fi' ? 'Finnish' : R.path(['locale'], action) === 'en' ? 'English' : 'Swedish'
      }
    case CLEAR_DATA_CMS:
      return initialState
    default:
      return state;
  }
}
